<template>
    <v-dialog v-model="isOpen" width="50%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Modification acquéreur
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text v-if="buyer !== null">
                <v-tabs class="mb-4" v-model="view">
                    <v-tab> Acquéreur </v-tab>
                    <v-tab> Notaire </v-tab>
                    <v-tab v-show="buyer.familysituation === 2"> Mariage </v-tab>
                    <v-tab v-show="buyer.familysituation === 3"> PACS </v-tab>
                    <v-tab v-show="[2, 3].includes(buyer.familysituation)"> Notaire rédacteur </v-tab>
                </v-tabs>

                <v-tabs-items v-model="view">
                    <ValidationObserver ref="buyerObserver">
                        <v-tab-item eager>
                            <v-row class="my-1">
                                <v-col>
                                    <v-row v-if="getConfig('sales.fields.contact_title.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_title.name', 'Civilité') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-select v-model="buyer.title" :items="['Mr', 'Mme', 'Mlle', 'SCI']" placeholder="Civilité" clear-icon="far fa-times-circle" :menu-props="{ bottom: true, offsetY: true }" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="getConfig('sales.fields.contact_name.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_name.name', 'Nom') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <ValidationProvider v-slot="{ errors, failed }" name="Nom" rules="required">
                                                <v-text-field v-model="buyer.name" placeholder="Nom" :hide-details="!failed" :error-messages="errors" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="getConfig('sales.fields.contact_maidenname.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_maidenname.name', 'Nom de jeune fille') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <ValidationProvider v-slot="{ errors, failed }" name="Nom de jeune fille" rules="">
                                                <v-text-field v-model="buyer.maidenname" placeholder="Nom de jeune fille" :hide-details="!failed" :error-messages="errors" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="getConfig('sales.fields.contact_firstname.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_firstname.name', 'Prénom') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.firstname" placeholder="Prénom" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="getConfig('sales.fields.contact_birth_date.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_birth_date.name', 'Date de naissance') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <DatePickerMenu v-model="buyer.birthdate" :placeholder="getConfig('sales.fields.contact_birth_date.name', 'Date de naissance')" />
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Lieu de naissance
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.birthplace" placeholder="Lieu de naissance" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Nationalité
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.nationality" placeholder="Nationalité" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="getConfig('sales.fields.contact_csp.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_csp.name', 'Catégorie socio-professionnelle') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-select v-model="buyer.csp" :items="getParameter('CTR_CSP')" item-value="key" item-text="value" placeholder="Catégorie socio-professionnelle" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Profession
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.profession" placeholder="Profession" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="getConfig('sales.fields.contact_family_situation.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_family_situation.name', 'Situation conjugale') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-select v-model="buyer.familysituation" :items="familySituationItems" :placeholder="getConfig('sales.fields.contact_family_situation.name', 'Situation conjugale')" clear-icon="far fa-times-circle" :menu-props="{ bottom: true, offsetY: true }" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="isMainBuyer && getConfig('sales.fields.contact_children.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_children.name', 'Nombre d’enfants à charge') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-select v-model="buyer.children" :items="childrenItems" placeholder="Nombre d’enfants à charge" clear-icon="far fa-times-circle" :menu-props="{ bottom: true, offsetY: true }" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="isMainBuyer && getConfig('sales.fields.contact_earnings_amount.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_earnings_amount.name', 'Revenu mensuel') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <ValidationProvider v-slot="{ errors, failed }" name="Revenu mensuel" rules="number">
                                                <v-text-field v-model="buyer.earningsamount" placeholder="Revenu mensuel" :hide-details="!failed" :error-messages="errors" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="isMainBuyer && getConfig('sales.fields.contact_ptz.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_ptz.name', 'PTZ') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-select v-model="buyer.ptz" clearable :items="[{ text: 'Oui', value: true }, { text: 'Non', value: false }]" placeholder="PTZ" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="isMainBuyer && getConfig('sales.fields.contact_primo_accedant.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_primo_accedant.name', 'Primo-accédant') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-select v-model="buyer.primoAccedant" clearable :items="[{ text: 'Oui', value: 1 }, { text: 'Non', value: 2 }]" placeholder="Primo-accédant" hide-details outlined dense />
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-divider vertical />

                                <v-col>
                                    <v-row v-if="getConfig('sales.fields.contact_email.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_email.name', 'Email') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <ValidationProvider v-slot="{ errors, failed }" name="Email" rules="email">
                                                <v-text-field v-model="buyer.email" :placeholder="getConfig('sales.fields.contact_email.name', 'Email')" :hide-details="!failed" :error-messages="errors" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="getConfig('sales.fields.contact_mobile.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_mobile.name', 'Portable') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.mobile" :placeholder="getConfig('sales.fields.contact_mobile.name', 'Portable')" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Téléphone
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.phone" placeholder="Téléphone" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-divider class="mx-5 my-4" />

                                    <v-row v-if="getConfig('sales.fields.contact_address.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_address.name', 'Adresse') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.address" :placeholder="getConfig('sales.fields.contact_address.name', 'Adresse')" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="getConfig('sales.fields.contact_city.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_city.name', 'Ville') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.city" :placeholder="getConfig('sales.fields.contact_city.name', 'Ville')" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="getConfig('sales.fields.contact_zip.enabled', true)" align="center">
                                        <v-col class="py-1">
                                            {{ getConfig('sales.fields.contact_zip.name', 'Code postal') }}
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.zip" :placeholder="getConfig('sales.fields.contact_zip.name', 'Code postal')" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Pays
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.country" placeholder="Pays" hide-details outlined dense />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </ValidationObserver>

                    <ValidationObserver ref="notaryObserver">
                        <v-tab-item eager>
                            <v-row v-if="buyer.notary" class="my-1">
                                <v-col>
                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Nom
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.notary.name" placeholder="Nom" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Email
                                        </v-col>

                                        <v-col class="py-1">
                                            <ValidationProvider v-slot="{ errors, failed }" name="Email" rules="email">
                                                <v-text-field v-model="buyer.notary.email" placeholder="Email" :hide-details="!failed" :error-messages="errors" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Téléphone
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.notary.phone" placeholder="Téléphone" hide-details outlined dense />
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-divider vertical />

                                <v-col>
                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Adresse
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.notary.address" placeholder="Adresse" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Code postal
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.notary.zip" placeholder="Code postal" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Ville
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.notary.city" placeholder="Ville" hide-details outlined dense />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </ValidationObserver>

                    <ValidationObserver ref="marieObserver">
                        <v-tab-item eager>
                            <v-row v-if="isMariage" class="my-1">
                                <v-col>
                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Ville
                                            <span v-if="getConfig('sales.fields.contact_union_place.required')" class="red--text">
                                                *
                                            </span>
                                        </v-col>

                                        <v-col class="py-1">
                                            <ValidationProvider v-slot="{ errors, failed }" name="Ville" :rules="getConfig('sales.fields.contact_union_place.required') ? getConfig('sales.fields.contact_union_place.rules') : ''">
                                                <v-text-field v-model="buyer.unionplace" placeholder="Ville" :hide-details="!failed" :error-messages="errors" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Date
                                            <span v-if="getConfig('sales.fields.contact_union_date.required')" class="red--text">
                                                *
                                            </span>
                                        </v-col>

                                        <v-col class="py-1">
                                            <ValidationProvider v-slot="{ errors, failed }" name="Date" :rules="getConfig('sales.fields.contact_union_date.required') ? getConfig('sales.fields.contact_union_date.rules') : ''">
                                                <DatePickerMenu v-model="buyer.uniondate" placeholder="Date" :failed="failed" :errors="errors" />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Régime
                                            <span v-if="getConfig('sales.fields.contact_union_type.required')" class="red--text">
                                                *
                                            </span>
                                        </v-col>

                                        <v-col class="py-1">
                                            <ValidationProvider v-slot="{ errors, failed }" name="Régime" :rules="getConfig('sales.fields.contact_union_type.required') ? getConfig('sales.fields.contact_union_type.rules') : ''">
                                                <v-select v-model="buyer.uniontype" :items="['Communauté légale réduite aux acquêts', 'Séparation de biens', 'Communauté universelle', 'Participation aux acquêts']" placeholder="Régime" :hide-details="!failed" :error-messages="errors" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-divider vertical />

                                <v-col>
                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Contrat de mariage
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-switch v-model="buyer.unioncontract" placeholder="Contrat de mariage" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row align="center" v-show="buyer.unioncontract">
                                        <v-col class="py-1">
                                            Date du contrat
                                            <span v-if="getConfig('sales.fields.contact_union_contract_date.required') && buyer.unioncontract" class="red--text">
                                                *
                                            </span>
                                        </v-col>

                                        <v-col class="py-1">
                                            <ValidationProvider v-slot="{ errors, failed }" name="Date du contrat" :rules="getConfig('sales.fields.contact_union_contract_date.required') && buyer.unioncontract ? getConfig('sales.fields.contact_union_contract_date.rules') : ''">
                                                <DatePickerMenu v-model="buyer.unioncontractdate" placeholder="Date du contrat" :failed="failed" :errors="errors" />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </ValidationObserver>

                    <ValidationObserver ref="pacsObserver">
                        <v-tab-item eager>
                            <v-row v-if="isPACS" class="my-1">
                                <v-col>
                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Tribunal d'instance
                                            <span v-if="getConfig('sales.fields.contact_union_place.required')" class="red--text">
                                                *
                                            </span>
                                        </v-col>

                                        <v-col class="py-1">
                                            <ValidationProvider v-slot="{ errors, failed }" name="Tribunal d'instance" :rules="getConfig('sales.fields.contact_union_place.required') ? getConfig('sales.fields.contact_union_place.rules') : ''">
                                                <v-text-field v-model="buyer.unionplace" placeholder="Tribunal d'instance" :hide-details="!failed" :error-messages="errors" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Date
                                            <span v-if="getConfig('sales.fields.contact_union_date.required')" class="red--text">
                                                *
                                            </span>
                                        </v-col>

                                        <v-col class="py-1">
                                            <ValidationProvider v-slot="{ errors, failed }" name="Date" :rules="getConfig('sales.fields.contact_union_date.required') ? getConfig('sales.fields.contact_union_date.rules') : ''">
                                                <DatePickerMenu v-model="buyer.uniondate" :errors="errors" :failed="failed" placeholder="Date" />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Régime
                                            <span v-if="getConfig('sales.fields.contact_union_type.required')" class="red--text">
                                                *
                                            </span>
                                        </v-col>

                                        <v-col class="py-1">
                                            <ValidationProvider v-slot="{ errors, failed }" name="Régime" :rules="getConfig('sales.fields.contact_union_type.required') ? getConfig('sales.fields.contact_union_type.rules') : ''">
                                                <v-select v-model="buyer.uniontype" :items="['Communauté légale réduite aux acquêts', 'Séparation de biens', 'Communauté universelle', 'Participation aux acquêts']" placeholder="Régime" :hide-details="!failed" :error-messages="errors" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-divider vertical />

                                <v-col>
                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Date du contrat
                                            <span v-if="getConfig('sales.fields.contact_union_contract_date.required')" class="red--text">
                                                *
                                            </span>
                                        </v-col>

                                        <v-col class="py-1">
                                            <ValidationProvider v-slot="{ errors, failed }" name="Date du contrat" :rules="getConfig('sales.fields.contact_union_contract_date.required') ? getConfig('sales.fields.contact_union_contract_date.rules') : ''">
                                                <DatePickerMenu v-model="buyer.unioncontractdate" placeholder="Date du contrat" :errors="errors" :failed="failed" />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </ValidationObserver>

                    <ValidationObserver ref="unionNotaryObserver">
                        <v-tab-item eager>
                            <v-row v-if="buyer.unionnotary" class="my-1">
                                <v-col>
                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Nom
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.unionnotary.name" placeholder="Nom" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Email
                                        </v-col>

                                        <v-col class="py-1">
                                            <ValidationProvider v-slot="{ errors, failed }" name="Email" rules="email">
                                                <v-text-field v-model="buyer.unionnotary.email" placeholder="Email" :hide-details="!failed" :error-messages="errors" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Téléphone
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.unionnotary.phone" placeholder="Téléphone" hide-details outlined dense />
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-divider vertical />

                                <v-col>
                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Adresse
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.unionnotary.address" placeholder="Adresse" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Code postal
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.unionnotary.zip" placeholder="Code postal" hide-details outlined dense />
                                        </v-col>
                                    </v-row>

                                    <v-row align="center">
                                        <v-col class="py-1">
                                            Ville
                                        </v-col>

                                        <v-col class="py-1">
                                            <v-text-field v-model="buyer.unionnotary.city" placeholder="Ville" hide-details outlined dense />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </ValidationObserver>
                </v-tabs-items>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import DatePickerMenu from '../../widgets/DatePickerMenu.vue';

export default {
    name: 'BuyerEditionDialog',

    components: {
        DatePickerMenu
    },

    props: {
        saleId: { type: Number, required: true },
        initBuyer: { type: Object, required: true }
    },

    data: () => ({
        isOpen: false,
        view: 0,
        buyer: null
    }),

    methods: {
        async submit() {
            const buyerValid = await this.$refs.buyerObserver.validate();
            if (!buyerValid) {
                this.view = 0;
                return;
            }

            const notaryValid = await this.$refs.notaryObserver.validate();
            if (!notaryValid) {
                this.view = 1;
                return;
            }

            const marieValid = await this.$refs.marieObserver.validate();
            if (!marieValid) {
                this.view = 2;
                return;
            }

            const pacsValid = await this.$refs.pacsObserver.validate();
            if (!pacsValid) {
                this.view = 3;
                return;
            }

            const unionNotaryValid = await this.$refs.unionNotaryObserver.validate();
            if (!unionNotaryValid) {
                this.view = 4;
                return;
            }

            try {
                this.setLoading(true);

                const buyer = JSON.parse(JSON.stringify(this.buyer));

                if (typeof buyer.earningsamount === 'string') {
                    buyer.earningsamount = buyer.earningsamount.replace(',', '.').replace(' ', '');
                }

                if (Object.keys(buyer.notary).length === 0) {
                    buyer.notary = null;
                }

                if (Object.keys(buyer.unionnotary).length === 0) {
                    buyer.unionnotary = null;
                }

                const body = {
                    buyers: [buyer]
                };

                const { success, err } = await this.repos.sales.updateSale(this.saleId, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('reload');
                } else {
                    if (err) {
                        console.error(err);
                        throw new Error();
                    } else {
                        // 
                    }
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la mise à jour de l\'acquéreur',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    computed: {
        isMainBuyer() {
            return this.buyer.type === 0;
        },

        familySituationItems() {
            const parameter = this.getParameter('CTR_SITUATION_FAMILLE');
            if (parameter === null) {
                return [];
            }
            return parameter.map((v) => ({ value: parseFloat(v.key), text: v.value }));
        },

        childrenItems() {
            const parameter = this.getParameter('CTR_ENFANTS');
            if (parameter === null) {
                return [];
            }
            return parameter.map((v) => ({ value: parseFloat(v.key), text: v.value }));
        },

        hasMariageRequiredFields() {
            return this.getConfig('sales.fields.contact_union_place.required')
                || this.getConfig('sales.fields.contact_union_date.required')
                || this.getConfig('sales.fields.contact_union_type.required')
                || (this.getConfig('sales.fields.contact_union_contract_date.required') && this.buyer.unioncontract);
        },

        isMariage() {
            return this.buyer.familysituation === 2;
        },

        isPACS() {
            return this.buyer.familysituation === 3;
        }
    },

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.buyer = JSON.parse(JSON.stringify(this.initBuyer));
            }
        },

        buyer() {
            if (!this.buyer.notary) {
                this.buyer.notary = {};
            }

            if (!this.buyer.unionnotary) {
                this.buyer.unionnotary = {};
            }
        },

        'buyer.familysituation'(newValue, previousValue) {
            if (previousValue) {
                this.buyer.unionplace = null;
                this.buyer.uniondate = null;
                this.buyer.uniontype = null;
                this.buyer.unioncontract = false;
                this.buyer.unioncontractdate = null;
            }
        }
    }
};
</script>
