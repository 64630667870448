<template>
    <v-card class="axessia-card my-1 mr-1" elevation="2">
        <v-toolbar class="toolbar-underline mb-2 mx-3" dense flat>
            <v-toolbar-title class="title primary--text">
                <template v-if="isMainBuyer">
                    Acquéreur principal
                </template>
                <template v-else>
                    Co-acquéreur
                </template>

                <v-chip label>
                    {{ buyer.name }} {{ buyer.firstname }}
                </v-chip>

                <v-item-group v-model="view" v-if="buyer.notary !== null || buyer.unionnotary" class="d-inline-block ml-4" mandatory>
                    <v-item :value="'buyer'" class="mx-4" v-slot="{ active, toggle }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="toggle" :color="active ? 'primary' : 'grey'" icon small v-on="on">
                                    <v-icon small> fas fa-user </v-icon>
                                </v-btn>
                            </template>
                            Afficher l'acquéreur
                        </v-tooltip>
                    </v-item>

                    <v-item :value="'notary'" v-if="buyer.notary !== null" v-slot="{ active, toggle }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="toggle" :color="active ? 'primary' : 'grey'" icon small v-on="on">
                                    <v-icon small> fas fa-feather-alt </v-icon>
                                </v-btn>
                            </template>
                            Afficher le notaire
                        </v-tooltip>
                    </v-item>

                    <v-item :value="'unionnotary'" v-if="buyer.unionnotary !== null" v-slot="{ active, toggle }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="toggle" :color="active ? 'primary' : 'grey'" icon small v-on="on">
                                    <v-icon small> fas fa-feather-alt </v-icon>
                                </v-btn>
                            </template>
                            Afficher le notaire rédacteur
                        </v-tooltip>
                    </v-item>
                </v-item-group>
            </v-toolbar-title>

            <v-spacer />

            <BuyerEditionDialog :saleId="sale.id" :initBuyer="newBuyer" @reload="$emit('reload')">
                <template v-slot:activator="{ on: dialog }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-if="isMainBuyer" color="primary" class="mr-2" :disabled="disableContactsEdition" x-small v-on="{ ... dialog, ...tooltip }">
                                Ajouter
                            </v-btn>
                        </template>
                        Ajouter un nouvel acquéreur
                    </v-tooltip>
                </template>
            </BuyerEditionDialog>

            <BuyerDeletionDialog :saleId="sale.id" :buyer="buyer" @reload="$emit('reload')">
                <template v-slot:activator="{ on: dialog }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-if="!isMainBuyer" color="error" class="mr-2" :disabled="disableContactsEdition" x-small v-on="{ ... dialog, ...tooltip }">
                                Supprimer
                            </v-btn>
                        </template>
                        Supprimer l'acquéreur
                    </v-tooltip>
                </template>
            </BuyerDeletionDialog>

            <BuyerEditionDialog :saleId="sale.id" :initBuyer="buyer" @reload="$emit('reload')">
                <template v-slot:activator="{ on }">
                    <v-btn color="primary" :disabled="disableContactsEdition" outlined x-small v-on="on">
                        Modifier
                    </v-btn>
                </template>
            </BuyerEditionDialog>
        </v-toolbar>

        <v-card-text>
            <v-window v-model="view">
                <v-window-item :value="'buyer'">
                    <v-row>
                        <v-col cols="6">
                            <v-row v-if="getConfig('sales.fields.contact_title.enabled', true)" no-gutters>
                                <v-col cols="6">
                                    {{ getConfig('sales.fields.contact_title.name', 'Civilité') }}
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.title }}
                                </v-col>
                            </v-row>

                            <v-row v-if="getConfig('sales.fields.contact_name.enabled', true)" no-gutters>
                                <v-col cols="6">
                                    {{ getConfig('sales.fields.contact_name.name', 'Nom') }}
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.name }}
                                </v-col>
                            </v-row>

                            <v-row v-if="getConfig('sales.fields.contact_maidenname.enabled', true)" no-gutters>
                                <v-col cols="6">
                                    {{ getConfig('sales.fields.contact_maidenname.name', 'Nom') }}
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.maidenname }}
                                </v-col>
                            </v-row>

                            <v-row v-if="getConfig('sales.fields.contact_firstname.enabled', true)" no-gutters>
                                <v-col cols="6">
                                    {{ getConfig('sales.fields.contact_firstname.name', 'Prénom') }}
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.firstname }}
                                </v-col>
                            </v-row>

                            <v-row v-if="getConfig('sales.fields.contact_birth_date.enabled', true)" no-gutters>
                                <v-col cols="6">
                                    {{ getConfig('sales.fields.contact_birth_date.name', 'Date de naissance') }}
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.birthdate | toDate() }}
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6">
                                    Lieu de naissance
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.birthplace }}
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6">
                                    Nationalité
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.nationality }}
                                </v-col>
                            </v-row>

                            <v-row v-if="getConfig('sales.fields.contact_csp.enabled', true)" no-gutters>
                                <v-col cols="6">
                                    {{ getConfig('sales.fields.contact_csp.name', 'Catégorie socio-professionnelle') }}
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.csp }}
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6">
                                    Profession
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.profession }}
                                </v-col>
                            </v-row>

                            <v-row v-if="getConfig('sales.fields.contact_family_situation.enabled', true)" no-gutters>
                                <v-col cols="6">
                                    {{ getConfig('sales.fields.contact_family_situation.name', 'Situation conjugale') }}
                                </v-col>

                                <v-col class="black--text">
                                    {{ getParameterValue('CTR_SITUATION_FAMILLE', buyer.familysituation) }}
                                </v-col>
                            </v-row>

                            <v-row v-if="getConfig('sales.fields.contact_children.enabled', true) && isMainBuyer" no-gutters>
                                <v-col cols="6">
                                    Nombre d’enfants à charge
                                </v-col>

                                <v-col class="black--text">
                                    {{ getParameterValue('CTR_ENFANTS', buyer.children) }}
                                </v-col>
                            </v-row>

                            <v-row v-if="isMainBuyer && getConfig('sales.fields.contact_earnings_amount.enabled', true)" no-gutters>
                                <v-col cols="6">
                                    {{ getConfig('sales.fields.contact_earnings_amount.name', 'Revenu mensuel') }}
                                </v-col>

                                <v-col class="black--text">
                                    <template v-if="buyer.earningsamount">
                                        {{ buyer.earningsamount | toCurrencyString(2) }}
                                    </template>
                                </v-col>
                            </v-row>

                            <v-row v-if="isMainBuyer && getConfig('sales.fields.contact_ptz.enabled', true)" no-gutters>
                                <v-col cols="6">
                                    {{ getConfig('sales.fields.contact_ptz.name', 'PTZ') }}
                                </v-col>

                                <v-col class="black--text">
                                    <template v-if="buyer.ptz !== null">
                                        {{ buyer.ptz ? 'Oui' : 'Non' }}
                                    </template>
                                </v-col>
                            </v-row>

                            <v-row v-if="isMainBuyer && getConfig('sales.fields.contact_primo_accedant.enabled', true)" no-gutters>
                                <v-col cols="6">
                                    {{ getConfig('sales.fields.contact_primo_accedant.name', 'Primo-accédant') }}
                                </v-col>

                                <v-col class="black--text">
                                    <template v-if="buyer.primoAccedant !== null">
                                        {{ buyer.primoAccedant === 1 ? 'Oui' : 'Non' }}    
                                    </template>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-divider class="my-5" vertical />

                        <v-col>
                            <v-row v-if="getConfig('sales.fields.contact_email.enabled', true)" no-gutters>
                                <v-col cols="6">
                                    {{ getConfig('sales.fields.contact_email.name', 'Email') }}
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.email }}
                                </v-col>
                            </v-row>

                            <v-row v-if="getConfig('sales.fields.contact_mobile.enabled', true)" no-gutters>
                                <v-col cols="6">
                                    {{ getConfig('sales.fields.contact_mobile.name', 'Portable') }}
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.mobile }}
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6">
                                    Téléphone
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.phone }}
                                </v-col>
                            </v-row>

                            <v-divider class="mx-5 my-2" />

                            <v-row v-if="getConfig('sales.fields.contact_address.enabled', true)" no-gutters>
                                <v-col cols="6">
                                    {{ getConfig('sales.fields.contact_address.name', 'Adresse') }}
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.address }}
                                </v-col>
                            </v-row>

                            <v-row v-if="getConfig('sales.fields.contact_city.enabled', true)" no-gutters>
                                <v-col cols="6">
                                    {{ getConfig('sales.fields.contact_city.name', 'Ville') }}
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.city }}
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6">
                                    Code postal
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.zip }}
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6">
                                    Pays
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.country }}
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-window-item>

                <v-window-item :value="'notary'" v-if="buyer.notary !== null">
                    <v-row>
                        <v-col cols="12" lg="6">
                            <v-row no-gutters>
                                <v-col cols="6">
                                    Nom
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.notary.name }}
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6">
                                    Email
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.notary.email }}
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6">
                                    Téléphone
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.notary.phone }}
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-divider class="my-5" vertical />

                        <v-col cols="12" lg="6">
                            <v-row no-gutters>
                                <v-col cols="6">
                                    Adresse
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.notary.address }}
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6">
                                    Code postal
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.notary.zip }}
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6">
                                    Ville
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.notary.city }}
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="text-right">
                            <BuyerNotaryDeletionDialog v-if="buyer.notary !== null" :saleId="sale.id" :buyer="buyer" :notary="buyer.notary" @reload="$emit('reload')">
                                <template v-slot:activator="{ on: dialog }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn color="error" class="mr-2" :disabled="disableContactsEdition" x-small v-on="{ ... dialog, ...tooltip }">
                                                Supprimer
                                            </v-btn>
                                        </template>
                                        Supprimer le notaire rédacteur associé
                                    </v-tooltip>
                                </template>
                            </BuyerNotaryDeletionDialog>
                        </v-col>
                    </v-row>
                </v-window-item>

                <v-window-item :value="'unionnotary'" v-if="buyer.unionnotary !== null">
                    <v-row>
                        <v-col cols="12" lg="6">
                            <v-row no-gutters>
                                <v-col cols="6">
                                    Nom
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.unionnotary.name }}
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6">
                                    Email
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.unionnotary.email }}
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6">
                                    Téléphone
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.unionnotary.phone }}
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-divider class="my-5" vertical />

                        <v-col cols="12" lg="6">
                            <v-row no-gutters>
                                <v-col cols="6">
                                    Adresse
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.unionnotary.address }}
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6">
                                    Code postal
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.unionnotary.zip }}
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6">
                                    Ville
                                </v-col>

                                <v-col class="black--text">
                                    {{ buyer.unionnotary.city }}
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="text-right">
                            <BuyerNotaryDeletionDialog v-if="buyer.unionnotary !== null" :saleId="sale.id" :buyer="buyer" :notary="buyer.unionnotary" @reload="$emit('reload')">
                                <template v-slot:activator="{ on: dialog }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn color="error" class="mr-2" :disabled="disableContactsEdition" x-small v-on="{ ... dialog, ...tooltip }">
                                                Supprimer
                                            </v-btn>
                                        </template>
                                        Supprimer le notaire associé
                                    </v-tooltip>
                                </template>
                            </BuyerNotaryDeletionDialog>
                        </v-col>
                    </v-row>
                </v-window-item>
            </v-window>
        </v-card-text>
    </v-card>
</template>

<script>
import BuyerEditionDialog from './buyer/BuyerEditionDialog.vue';
import BuyerDeletionDialog from './buyer/BuyerDeletionDialog.vue';
import BuyerNotaryDeletionDialog from './buyer/BuyerNotaryDeletionDialog.vue';

export default {
    name: 'BuyerCard',

    components: {
        BuyerEditionDialog,
        BuyerDeletionDialog,
        BuyerNotaryDeletionDialog
    },

    props: {
        sale: { type: Object, required: true },
        buyer: { type: Object, required: true }
    },

    data: () => ({
        view: 'buyer'
    }),

    computed: {
        isMainBuyer() {
            return this.buyer.type === 0;
        },

        newBuyer() {
            return this.sale.buyers.length === 1 ? { id: this.buyer.id, type: 1 } : { type: 2 };
        },

        disableContactsEdition() {
            return this.sale.status !== 'prereserved';
        }
    }
};
</script>
